.gallery_outer {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.gallery {
    max-width: 1220px;
    padding-top: 50px;
    padding-left: 10px;
    padding-right: 10px;
}

.gallery_image {
    width: 100%;
}