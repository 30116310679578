.kontakt_outer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
}

.kontakt_inner {
    max-width: 1220px;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.avatar_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 60px;
    @media (min-width: 600px) {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.text_wrapper {
    display: flex;
    vertical-align: center;
    align-items: center;
}