.musikverein_flex_div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.musikverein {
    max-width: 1200px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.grid_image {
    width: 100%;
    max-width: 100%;
    border-radius: 5%;
}
