.mvw_gesamt_img {
    width: 100%;
}

.main_page_outer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 20px;
}

.main_page_inner {
    max-width: 1220px;
    padding-left: 20px;
    padding-right: 20px;
}

.overview {
    width: 100%;
    padding-top: 50px ;
    padding-bottom: 10px;

}

.overview_image {
    width: 100%;
}

.konzertmeister_appointment_list {
    padding-top: 20px;
    padding-bottom: 20px;
}

.next_event_image {
    width: 100%;
    padding-bottom: 30px;
}

.overview_grid {
    padding-bottom: 30px;
}

.aktuelles_image {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
}
