.datenschutz_flex_div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.datenschutz {
    max-width: 1200px;
    padding-top: 20px;
    padding-bottom: 20px;
}
