.juka_image_gesamt {
    width: 100%;
    padding-bottom: 50px;
}

.juka_outer {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.juka_inner {
    max-width: 1220px;
    padding-left: 10px;
    padding-right: 10px;
}

.juka_grid {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    align-self: center;
}

.grid_image {
    width: 100%;
    max-width: 100%;
    border-radius: 5%;
}

.grid_text {
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 20px;
}

.ausbildung {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 8px;
}

.blockfloetenunterricht {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 8px;
}

