.footer_flex_div {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background-color: #851a34;
    color: #fff;
    min-height: 135px;
}

.footer {
    max-width: 1200px;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
}

.column {
    padding-right: 30px;
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.mail_link {
    color: white;
}

.site_link {
    height: 22px;
    padding-left: 0;
    justify-content: left;
    align-items: flex-start;
}