.toolbar {
    min-height: 135px;
}

.SocialButton {
    width: 50%;
}

.mvwLogo {
    width: 300px;
    padding-right: 20px;
}